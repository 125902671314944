import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/Layout'
import { getToken } from '@/utils/auth' // 验权

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
      path: '/redirect',
      component: Layout,
      hidden: true,
      children: [
          {
              path: '/redirect/:path*',
              component: () => import('@/views/redirect/index')
          }
      ]
  },
  {
    path: '/home',
    component: () => import('@/views/layout/Layout'),
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (getToken()) {
     next()
  } else {
    let data = to.path.split('/')
    if (data.indexOf('login') > -1) {
      next()
    } else {
      // 否则全部重定向到登录页
      next(`/login`)
    }
  }
})

export default router
